import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'

import Alert from '../Alert/Alert'
import { editProfileItem, setCreationOption } from '../../store/actions/user'
import { CREATION_OPTIONS } from '../../constants/Constants'
import AlertBox from '../Alert/AlertBox'
import { schoolIcons } from '../../assets/images/vota_school_logos/schoolIcons'

/**
 * @property: className: PropTypes.string.isRequired,
 * @property: onSave: PropTypes.func.isRequired,
 * @property: onCancel: PropTypes.func.isRequired,
 * @property: setLoadingValue: PropTypes.func.isRequired,
 */
class FormCreationOptions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            creation_option: null,
        }
        if (props.user?.profile?.creation_option) {
            this.state.creation_option = props.user.profile.creation_option
        }
    }

    options = [
        {
            value: CREATION_OPTIONS.ALREADY_TEACHING_VOTA,
            label: translate('creation_options.option_already_teaching_vota'),
        },
        {
            value: CREATION_OPTIONS.ALREADY_TEACHING_NOT_VOTA,
            label: translate('creation_options.option_already_teaching_not_vota'),
        },
        {
            value: CREATION_OPTIONS.STUDYING,
            label: translate('creation_options.option_studying'),
        },
        {
            value: CREATION_OPTIONS.EXPLORING,
            label: translate('creation_options.option_exploring'),
        },
    ]

    handleChange(value, key) {
        let obj = {}
        obj[key] = value.target.value
        this.setState(obj)
        this.setState({ errors: [] })
    }

    cancel() {
        this.setState({
            creation_option: null,
            errors: [],
        })
        this.props.onCancel()
    }

    save() {
        const { loading, setLoadingValue, dispatch } = this.props
        const { creation_option } = this.state

        if (loading || !creation_option) {
            if (!creation_option) {
                this.setState({ errors: [translate('creation_options.validation_error')] })
                setLoadingValue(false)
            }
            return
        }

        setLoadingValue(true)
        dispatch(
            editProfileItem(
                'general',
                {
                    creation_option,
                },
                this.props.auth,
                () => {
                    this.onSuccess()
                },
                (status, error) => {
                    this.onError(status, error)
                },
            ),
        )
    }

    onError(status, error) {
        this.props.setLoadingValue(false)
        if (error.errors && error.errors.length > 0) {
            this.setState({
                errors: error.errors.map(({ message }) => message),
            })
        } else {
            this.setState({
                errors: [translate('server_error')],
            })
        }
    }

    onSuccess() {
        this.props.setLoadingValue(false)
        this.setState({
            errors: [],
        })
        this.props.onSave()
    }

    render() {
        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <AlertBox
                            className="full-width"
                            messages={this.state.errors}
                            type="error"
                        />
                    )}

                    <div className="form__item">
                        <label className="form__top_label">
                            {translate('creation_options.sub_title')}
                        </label>
                        {this.options.map((option) => {
                            return (
                                <div
                                    className={`form__radio block ${
                                        this.state.errors.length > 0 ? 'error' : ''
                                    }`}
                                    key={option.value}
                                >
                                    <input
                                        type="radio"
                                        name="radio_styled"
                                        id={option.value}
                                        className="form__radio__input"
                                        checked={this.state.creation_option === option.value}
                                        onChange={(value) =>
                                            this.handleChange(value, 'creation_option')
                                        }
                                        value={option.value}
                                    />
                                    <label className="form__radio__label" htmlFor={option.value}>
                                        {option.label}
                                    </label>
                                </div>
                            )
                        })}
                        <div className="form__clarification">
                            <p>{translate('creation_options.school_list')}</p>
                            <div className="icons__container">
                                {Object.entries(schoolIcons).map(([name, src]) => (
                                    <img
                                        key={name}
                                        src={src}
                                        alt={`${name} icon`}
                                        className="form__clarification__image"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }
}

FormCreationOptions.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setLoadingValue: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}

FormCreationOptions.defaultProps = {
    loading: false,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormCreationOptions)
