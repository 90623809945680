export const schoolIcons = {
    Attendiz: require('./attendiz.svg').default,
    Carmel: require('./carmel.svg').default,
    Stedelijk: require('./stedelijk.svg').default,
    Nordijk: require('./nordijk.svg').default,
    Osg: require('./osg_almelo_hengelo.svg').default,
    Portuur: require('./portuur.svg').default,
    Reggesteyn: require('./reggesteyn.svg').default,
    Rvt: require('./roc_van_twente.svg').default,
    Waerdenborch: require('./waerdenborch.svg').default,
}
